export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const UPDATE_URL = "UPDATE_URL";
export const ADD_TO_CART = "ADD_TO_CART";
export const UPDATE_CART = "UPDATE_CART";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const SEARCH_CUSTOMER = "SEARCH_CUSTOMER";
export const SELECT_CUSTOMER = "SELECT_CUSTOMER";
export const SEARCH_PARTY = "SEARCH_PARTY";
export const SELECT_PARTY = "SELECT_PARTY";
export const UPDATE_CART_MODE_OF_PAYMENT = "UPDATE_CART_MODE_OF_PAYMENT";
export const UPDATE_CART_TOTAL_AMOUNT = "UPDATE_CART_TOTAL_AMOUNT";
export const UPDATE_CART_AMOUNT_REPAY = "UPDATE_CART_AMOUNT_REPAY";
export const UPDATE_CART_AMOUNT_PAID = "UPDATE_CART_AMOUNT_PAID";
export const UPDATE_CART_DISCOUNT = "UPDATE_CART_DISCOUNT";
export const SHOW_INVOICE = "SHOW_INVOICE";
export const CLEAR_CART = "CLEAR_CART";
export const CLEAR_CUSTOMER = "CLEAR_CUSTOMER";
export const SHOW_BILL = "SHOW_BILL";
