import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import {
  Table,
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap';
import billing from '../../api/billing';
import FullLayout from '../Layouts/FullLayout';

const columns = [
  {
    name: 'Item',
    selector: 'product_name',
    sortable: true,
  },
  {
    name: 'Remaining Qty',
    selector: 'rem_qty',
    sortable: true,
  },
  {
    name: 'Count',
    selector: 'count',
    sortable: true,
  },
];

export default class MostSoldItems extends React.Component {
  state = {
    items: [],
    filterText: '',
    isLoading: false,
    fromDate: new Date().toISOString().slice(0, 10),
    toDate: new Date().toISOString().slice(0, 10),
    error: '',
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await billing.get('most-sold-items');
      console.log(response);
      this.setState({ items: response.data.itemsWithCount });
      this.setState({ isLoading: false });
    } catch (err) {
      this.setState({ error: err.response.data.error, isLoading: false });
    }
  };

  // renderTableBody() {
  //   const { isLoading, items } = this.state;
  //   if (isLoading)
  //     return (
  //       <Spinner
  //         color='dark'
  //         style={{ display: 'flex', justifyContent: 'center' }}
  //       />
  //     );
  //   return (
  //     <>
  //       <tbody>
  //         {items.length > 0 ? (
  //           items.map((item, idx) => (
  //             <tr key={item.id}>
  //               <td>{idx + 1}</td>
  //               <td>{item.product_name}</td>
  //               <td>{item.rem_qty}</td>
  //               <td>{item.count}</td>
  //             </tr>
  //           ))
  //         ) : (
  //           <tr>
  //             <td colSpan='4' className='text-center'>
  //               No Item
  //             </td>
  //           </tr>
  //         )}
  //       </tbody>
  //     </>
  //   );
  // }

  async handleSubmit(e) {
    const { fromDate, toDate } = this.state;
    e.preventDefault();
    this.setState({ loading: true });
    try {
      this.setState({ loading: true });
      const response = await billing.get('most-sold-items', {
        params: {
          from: fromDate,
          to: toDate,
        },
      });
      this.setState({ items: response.data.itemsWithCount });
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ error: err.response.data.error });
    } finally {
      this.setState({ loading: false });
    }
  }

  setFilterText = (e) => {
    this.setState({ filterText: e.target.value });
  };

  render() {
    const { items, filterText, fromDate, toDate } = this.state;
    const filteredItems = items.filter(
      (item) =>
        item.product_name &&
        item.product_name.toLowerCase().includes(filterText.toLowerCase())
    );
    return (
      <FullLayout>
        <aside key='sidebar'>
          <h1>Most Sold Items Report</h1>
        </aside>
        <main key='main' className='card'>
          <div className='card-body'>
            <Form className='mb-3' onSubmit={this.handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for='fromDate'>From</Label>
                    <Input
                      type='date'
                      name='fromDate'
                      id='fromDate'
                      value={fromDate}
                      onChange={(e) =>
                        this.setState({
                          fromDate: new Date(e.target.value)
                            .toISOString()
                            .slice(0, 10),
                        })
                      }
                    />
                  </FormGroup>{' '}
                </Col>
                <Col>
                  <FormGroup>
                    <Label for='toDate'>To</Label>
                    <Input
                      type='date'
                      name='toDate'
                      id='toDate'
                      value={toDate}
                      onChange={(e) =>
                        this.setState({
                          toDate: new Date(e.target.value)
                            .toISOString()
                            .slice(0, 10),
                        })
                      }
                    />
                  </FormGroup>{' '}
                </Col>
              </Row>
              <Button>Search</Button>
            </Form>
            <input
              type='search'
              placeholder='Search by Item Name'
              className='form-control'
              onChange={this.setFilterText}
            />
            <DataTable
              title=''
              columns={columns}
              data={filteredItems}
              pagination
              fixedHeader
              noDataComponent={
                <Spinner
                  color='dark'
                  style={{ display: 'flex', justifyContent: 'center' }}
                />
              }
            />
            {/* <Table bordered striped>
              <thead>
                <tr>
                  <th>SNo.</th>
                  <th>Item</th>
                  <th>Remaining Qty.</th>
                  <th>Count</th>
                </tr>
              </thead>
              {this.renderTableBody()}
            </Table> */}
          </div>
        </main>
      </FullLayout>
    );
  }
}
