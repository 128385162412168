import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import {
  Table,
  Row,
  Col,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
  Spinner,
} from 'reactstrap';
import billing from '../../api/billing';
import FullLayout from '../Layouts/FullLayout';
import requireAuth from '../../helpers/requireAuth';

const columns = [
  {
    name: 'Invoice #',
    cell: (sale) => <Link to={`/invoice/${sale.id}`}>{'IN00' + sale.id}</Link>,
  },
  {
    name: 'Invoice Total',
    selector: 'total_amount',
    sortable: true,
  },
  {
    name: 'Total Profit',
    cell: (sale) => Number(sale.profit).toFixed(2),
    selector: 'profit',
    sortable: true,
  },
];

class SaleProfit extends Component {
  state = {
    sales: [],
    filterText: '',
    totalValue: 0,
    fromDate: new Date().toISOString().slice(0, 10),
    toDate: new Date().toISOString().slice(0, 10),
    isLoading: false,
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const response = await billing.get('sale-profit');
    this.setState({ sales: response.data.sales, isLoading: false }, () => {
      const total_value = Number(
        this.state.sales.reduce((total, sale) => total + sale.profit, 0)
      ).toFixed(2);
      this.setState({ totalValue: total_value });
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: new Date(e.target.value).toISOString().slice(0, 10),
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let { fromDate, toDate } = this.state;
    this.setState({ isLoading: true });
    const response = await billing.get('sale-profit', {
      params: {
        from: fromDate,
        to: toDate,
      },
    });
    this.setState({ sales: response.data.sales, isLoading: false }, () => {
      const total_value = Number(
        this.state.sales.reduce((total, sale) => total + sale.profit, 0)
      ).toFixed(2);
      this.setState({ totalValue: total_value });
    });
  };

  // renderTableBody = () => {
  //   const { sales, isLoading } = this.state;
  //   if (isLoading)
  //     return (
  //       <Spinner
  //         color='dark'
  //         style={{ display: 'flex', justifyContent: 'center' }}
  //       />
  //     );
  //   return (
  //     <>
  //       <tbody>
  //         {sales.length > 0 ? (
  //           sales.map((sale, idx) => (
  //             <tr key={sale.id}>
  //               <td>{idx + 1}</td>
  //               <td>
  //                 <Link to={`/invoice/${sale.id}`}>{'IN00' + sale.id}</Link>
  //               </td>
  //               <td>{Number(sale.total_amount).toFixed(2)}</td>
  //               <td>{Number(sale.profit).toFixed(2)}</td>
  //             </tr>
  //           ))
  //         ) : (
  //           <tr>
  //             <td colSpan='5' className='text-center'>
  //               No Sale
  //             </td>
  //           </tr>
  //         )}
  //       </tbody>
  //       {sales.length > 0 ? (
  //         <tfoot>
  //           <tr>
  //             <th colSpan='2'>Total</th>
  //             <th>
  //               {Number(
  //                 sales.reduce((total, sale) => total + sale.profit, 0)
  //               ).toFixed(2)}
  //             </th>
  //           </tr>
  //         </tfoot>
  //       ) : null}
  //     </>
  //   );
  // };

  setFilterText = (e) => {
    this.setState({ filterText: e.target.value });
  };

  render() {
    const { sales, filterText, fromDate, toDate, totalValue } = this.state;
    // const filteredSales = sales.filter(
    //   (sale) =>
    //     sale.customer &&
    //     sale.customer.name &&
    //     sale.customer.name.toLowerCase().includes(filterText.toLowerCase())
    // );
    return (
      <FullLayout>
        <aside key='sidebar'>
          <h1>Daily Sale Report</h1>
        </aside>
        <main key='main' className='card'>
          <div className='card-body'>
            <Form className='mb-3' onSubmit={this.handleSubmit}>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for='fromDate'>From</Label>
                    <Input
                      type='date'
                      name='fromDate'
                      id='fromDate'
                      value={fromDate}
                      onChange={this.handleChange}
                    />
                  </FormGroup>{' '}
                </Col>
                <Col>
                  <FormGroup>
                    <Label for='toDate'>To</Label>
                    <Input
                      type='date'
                      name='toDate'
                      id='toDate'
                      value={toDate}
                      onChange={this.handleChange}
                    />
                  </FormGroup>{' '}
                </Col>
                <Button hidden>Search</Button>
              </Row>
              <Button>Search</Button>
            </Form>
            <h5 className='text-right mt-5'>Total Value: {totalValue}</h5>
            <DataTable
              title=''
              columns={columns}
              data={sales}
              pagination
              fixedHeader
              noDataComponent={
                <Spinner
                  color='dark'
                  style={{ display: 'flex', justifyContent: 'center' }}
                />
              }
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
            />
            {/* <Table bordered striped>
              <thead>
                <tr>
                  <th>SNo.</th>
                  <th>Invoice #</th>
                  <th>Invoice Total</th>
                  <th>Total Profit</th>
                </tr>
              </thead>
              {this.renderTableBody()}
            </Table> */}
          </div>
        </main>
      </FullLayout>
    );
  }
}

export default requireAuth(SaleProfit);
