import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
// import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import SendSMS from '../Common/SendSms';
import billing from '../../api/billing';
import FullLayout from '../Layouts/FullLayout';

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Bought Qty',
    selector: 'total_sale',
    sortable: true,
    cell: (item) => Number(item.total_sale).toFixed(2),
  },
  {
    name: 'Price',
    selector: 'total_price',
    sortable: true,
    cell: (item) => Number(item.total_price).toFixed(2),
  },
  {
    name: 'Last Bought',
    selector: 'last_bought',
    sortable: true,
  },
  {
    name: '',
    cell: (item) => (
      <SendSMS btnSize='sm' title='Send SMS' customers={[item.phone]} />
    ),
  },
];

class CustomerList extends Component {
  state = {
    items: [],
    filterText: '',
    selectedCustomers: [],
    isLoading: false,
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const { id } = this.props.match.params;
    if (id) {
      const response = await billing.get(`item-sales?id=${id}`);
      this.setState({ items: response.data.items });
    }
    this.setState({ isLoading: false });
  };

  setFilterText = (e) => {
    this.setState({ filterText: e.target.value });
  };

  handleRowsSelected = (state) => {
    console.log(state.selectedRows);
    const phones = state.selectedRows.map((row) => row.phone);
    this.setState({ selectedCustomers: phones });
  };

  render() {
    const { items, filterText } = this.state;
    const filteredItems = items.filter(
      (item) =>
        item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
    );
    return (
      <FullLayout>
        <aside key='sidebar'>
          <h1>Item</h1>
        </aside>
        <main key='main' className='card'>
          <div className='card-body'>
            {/* <Table className='mt-2' bordered striped>
              <thead>
                <tr>
                  <th>SNo.</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Total Paid</th>
                  <th>Total Credit</th>
                  <th></th>
                </tr>
              </thead>
              {this.renderTableBody()}
            </Table> */}
            <SendSMS
              disabled={!(this.state.selectedCustomers.length > 1)}
              customers={this.state.selectedCustomers}
              title='Send SMS to Selected'
            />
            <h3 className='text-center mb-3'>
              Item {this.props.match.params.name}
            </h3>
            <input
              type='search'
              placeholder='Search by Customer Name'
              className='form-control'
              onChange={this.setFilterText}
            />
            <DataTable
              title=''
              columns={columns}
              data={filteredItems}
              pagination
              fixedHeader
              noDataComponent={
                <Spinner
                  color='dark'
                  style={{ display: 'flex', justifyContent: 'center' }}
                />
              }
              selectableRows
              onSelectedRowsChange={this.handleRowsSelected}
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
            />
          </div>
        </main>
      </FullLayout>
    );
  }
}

export default CustomerList;
