import React, { Component } from 'react';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Table, Spinner } from 'reactstrap';

import billing from '../../api/billing';
import FullLayout from '../Layouts/FullLayout';
import SendSMS from '../Common/SendSms';

const columns = [
  {
    name: 'Name',
    selector: 'name',
    sortable: true,
  },
  {
    name: 'Phone',
    cell: (customer) => (
      <Link to={`customer/${customer.phone}`}>{customer.phone}</Link>
    ),
  },
  {
    name: 'Total Paid',
    selector: 'paid_total',
    sortable: true,
    cell: (customer) => Number(customer.paid_total).toFixed(2),
  },
  {
    name: 'Total Credit',
    selector: 'credit_total',
    sortable: true,
    cell: (customer) => Number(customer.credit_total).toFixed(2),
  },
  {
    name: 'Last Invoice Date',
    cell: (customer) =>
      customer.sales.length > 0
        ? customer.sales[customer.sales.length - 1].created_at
        : '-',
  },
  {
    name: 'Points',
    selector: 'points',
    sortable: true,
  },
  {
    name: '',
    cell: (customer) => (
      <SendSMS btnSize='sm' title='Send SMS' customers={[customer.phone]} />
    ),
  },
  {
    name: '',
    cell: (customer) => (
      <Link to={`/customers/${customer.id}`}>
        All Invoices{' '}
        <span style={{ fontSize: 12 }}>({customer.sales.length || 0})</span>
      </Link>
    ),
  },
];

class CustomerList extends Component {
  state = {
    customers: [],
    filterText: '',
    selectedCustomers: [],
    isLoading: false,
  };

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    const response = await billing.get('customers');
    this.setState({ customers: response.data.customers, isLoading: false });
  };

  // renderTableBody = () => {
  //   const { customers, isLoading } = this.state;
  //   if (isLoading)
  //     return (
  //       <Spinner
  //         color='dark'
  //         style={{ display: 'flex', justifyContent: 'center' }}
  //       />
  //     );
  //   return (
  //     <>
  //       <tbody>
  //         {customers.length > 0 ? (
  //           this.customersAfterPaging().map((customer, idx) => (
  //             <tr key={customer.id}>
  //               <td>{idx + 1}</td>
  //               <td>{customer.name}</td>
  //               <td>
  //                 <Link to={`customer/${customer.phone}`}>
  //                   {customer.phone}
  //                 </Link>
  //               </td>
  //               <td>{Number(customer.paid_total).toFixed(2)}</td>
  //               <td>{Number(customer.credit_total).toFixed(2)}</td>
  //               <td>
  //                 <Link to={`/customers/${customer.id}`}>
  //                   All Invoices{' '}
  //                   <span style={{ fontSize: 12 }}>
  //                     ({customer.sales.length || 0})
  //                   </span>
  //                 </Link>
  //               </td>
  //             </tr>
  //           ))
  //         ) : (
  //           <tr>
  //             <td colSpan='6' className='text-center'>
  //               No Customer
  //             </td>
  //           </tr>
  //         )}
  //       </tbody>
  //     </>
  //   );
  // };

  setFilterText = (e) => {
    this.setState({ filterText: e.target.value });
  };

  handleRowsSelected = (state) => {
    const phones = state.selectedRows.map((row) => row.phone);
    this.setState({ selectedCustomers: phones });
  };

  render() {
    const { customers, filterText } = this.state;
    const filteredItems = customers.filter(
      (customer) =>
        customer.name &&
        customer.name.toLowerCase().includes(filterText.toLowerCase())
    );
    return (
      <FullLayout>
        <aside key='sidebar'>
          <h1>Customer List</h1>
        </aside>
        <main key='main' className='card'>
          <div className='card-body'>
            {/* <Table className='mt-2' bordered striped>
              <thead>
                <tr>
                  <th>SNo.</th>
                  <th>Name</th>
                  <th>Phone</th>
                  <th>Total Paid</th>
                  <th>Total Credit</th>
                  <th></th>
                </tr>
              </thead>
              {this.renderTableBody()}
            </Table> */}
            <SendSMS
              disabled={!(this.state.selectedCustomers.length > 1)}
              customers={this.state.selectedCustomers}
              title='Send SMS to Selected'
            />
            <h3 className='text-center mb-3'>Customers</h3>
            <input
              type='search'
              placeholder='Search by Customer Name'
              className='form-control'
              onChange={this.setFilterText}
            />
            <DataTable
              title=''
              columns={columns}
              data={filteredItems}
              pagination
              fixedHeader
              noDataComponent={
                <Spinner
                  color='dark'
                  style={{ display: 'flex', justifyContent: 'center' }}
                />
              }
              selectableRows
              onSelectedRowsChange={this.handleRowsSelected}
              paginationRowsPerPageOptions={[10, 15, 20, 25, 30, 50, 100]}
            />
          </div>
        </main>
      </FullLayout>
    );
  }
}

export default CustomerList;
