import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import axios from 'axios';

const SMS_API_KEY =
  'GuVjXR7P1A0ECOLydIHQviBfY3pq8gzo2bUsl4DkNw6WxhJtFS0MKgtph7fbsTPDLjq9eaNFRHmQIOck';

const SendSms = (props) => {
  const { disabled, btnSize, title, customers } = props;
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState('');
  const [returnMessage, setReturnMessage] = useState('');

  const toggle = () => setModal(!modal);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await axios.get(
      `https://www.fast2sms.com/dev/bulkV2?authorization=${SMS_API_KEY}&sender_id=TXTIND&message=${message}&route=v3&numbers=${customers.join()}`
    );

    if (response.data.return) setMessage('');
    setReturnMessage(response.data.message.join());
  };

  return (
    <div>
      <Button
        disabled={disabled}
        color='success'
        size={btnSize}
        onClick={toggle}
      >
        {title}
      </Button>
      <Modal isOpen={modal} toggle={toggle} backdrop='static'>
        <ModalHeader toggle={toggle}>
          Send SMS to{' '}
          {customers.length > 1
            ? `${customers[0]} and ${customers.length - 1} other(s)`
            : customers[0]}
        </ModalHeader>
        <Form onSubmit={handleSubmit}>
          <ModalBody>
            <FormGroup>
              <Label for='message'>SMS Text</Label>
              <Input
                type='textarea'
                name='text'
                id='message'
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </FormGroup>
            <p className='text-info'>{returnMessage}</p>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' type='submit'>
              Send SMS
            </Button>{' '}
            <Button color='secondary' onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

SendSms.propTypes = {
  disabled: PropTypes.bool,
  btnSize: PropTypes.string,
  title: PropTypes.string,
  customers: PropTypes.array.isRequired,
};

SendSms.defaultProps = {
  disabled: false,
  btnSize: 'md',
  title: 'Send SMS',
};

export default SendSms;
